import React, { useState, useEffect } from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { fbfsdb } from '../../authentication/firebase';
import { Virtuoso } from 'react-virtuoso';
import ActivityRow from '../ActivityRow';
import ProgressBar from '../ProgressBar';

function EventDetails(props) {
  const [currentlyTraining, setCurrentlyTraining] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventType, setEventType] = useState('');
  const [eventStart, setEventStart] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [daysDelta, setDaysDelta] = useState('');
  const [daysSinceStart, setDaysSinceStart] = useState('');
  const [daysLeft, setDaysLeft] = useState('');
  const [pastEvent, setPastEvent] = useState(false);
  const [futureEvent, setFutureEvent] = useState(false);
  const [inRange, setInRange] = useState([]);
  const [hoursTrained, setHoursTrained] = useState(0);

  const confirmDialog = (msg) => {
    return new Promise(function (resolve, reject) {
      let confirmed = window.confirm(msg);
      return confirmed ? resolve(true) : reject(false);
    });
   }

  const deleteEvent = async(e) => {
    confirmDialog('Are you sure you want to delete this event? This will not delete your Strava activities.')
    .then(async() => {
      await deleteDoc(doc(fbfsdb, `users/${props.user}/events`, eventName));
      props.fetch_data();
      props.setModalIsOpen(false)
    })
    .catch()
  }

  function toDateTime(secs) {
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(secs);
  
    let month = d.getMonth()  // 10 (PS: +1 since Month is 0-based)
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    let day = d.getDate()       // 30
    let year = d.getFullYear()
  
    return `${monthNames[month]} ${day}, ${year}`;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    // access the activities data for this event.
    let userActivities = props.userActivitiesArr;
    if (userActivities && userActivities.length > 0) {
      userActivities.forEach((activity) => {
        // if activity start is inbetween start date and event date.
          // add activity to inRange array.
        let activityDT = new Date(activity['start_date']).getTime() / 1000

        if (activityDT > eventStart && activityDT < eventDate) {
          setInRange(inRange => [...inRange, activity])
          setHoursTrained(hoursTrained => hoursTrained + (activity['elapsed_time'] / 60 / 60))
        }
      })
    }

    setEventName(props.selectedEvent);
    setEventType(props.eventData[props.selectedEvent]['eventType']);
    setEventStart(props.eventData[props.selectedEvent]['trainingStart']['seconds']);
    setEventDate(props.eventData[props.selectedEvent]['eventDate']['seconds']);
    
    var startd = new Date(0);
    startd.setUTCSeconds(props.eventData[props.selectedEvent]['trainingStart']['seconds']);

    var endd = new Date(0);
    endd.setUTCSeconds(props.eventData[props.selectedEvent]['eventDate']['seconds']);

    setDaysDelta(parseInt((endd - startd) / (1000 * 3600 * 24)));

    var currentDT = new Date();
    if (currentDT > startd && currentDT < endd) {
      // user is currently training, so show a progress bar.
      setCurrentlyTraining(true);
      setDaysSinceStart(parseInt((currentDT - startd) / (1000 * 3600 * 24)));
      setDaysLeft(parseInt((endd - currentDT) / (1000 * 3600 * 24)));
    }
    else if (currentDT > startd && currentDT > endd) {
      // event was in the past
      setPastEvent(true);
    }
    else if (currentDT < startd && currentDT < endd) {
      // event is in the future
      setFutureEvent(true);
    }
  }, [props, eventDate, eventStart])
  
  // useEffect to get strava data
  return (
    <div id='eventDetailsModal'>
      <h3>{eventName}</h3>
      <div>
        <div>Event Type: {capitalizeFirstLetter(eventType)}</div>
        {/* this logic should contain details about the event:
          -days spent training
          -days left until event (if applicable)
          -overall hours spent training
          -event type
          -event date
          -training start date
        */}
        {
          currentlyTraining &&
          <>
            <div className='percentageContainer'>
              <span>{toDateTime(eventStart)}</span>
              <ProgressBar percentCompleted={parseInt(daysSinceStart/daysDelta * 100)} />
              <span>{toDateTime(eventDate)}</span>
            </div>
            <div>
              <div className='training-details'>{`${daysSinceStart} / ${daysDelta} days of training for this event. `}</div>
              <div className='training-details'>{`There ${daysLeft > 1 ? 'are' : 'is'} ${daysLeft} ${daysLeft > 1 ? 'days' : 'day'} until this event.`}</div>
            </div>
            <>
              {
                props.userActivitiesArr &&
                <>
                  <div>
                    You have recorded {inRange.length} activities totalling {hoursTrained.toFixed(2)} hours since you started training for this event:
                  </div>
                  <div className='activitieslabels'>
                    <div className='activitydetail'>Name</div>
                    <div className='activitydetail'>Date</div>
                    <div className='activitydetail'>Type</div>
                    <div className='activitydetail'>Hrs trained</div>
                    <div className='activitydetail'>Link</div>
                  </div>
                  <div className='activitiescontainer'>
                    {
                      inRange.length > 0 &&
                      <Virtuoso
                        className='activitiesvirtuoso'
                        data={inRange}
                        increaseViewportBy={200}
                        itemContent={(index, item) => {
                          return (
                            <ActivityRow className={index % 2 === 0 ? "RowEven" : "RowOdd"} key={item.index} index={item.index} item={item}/>
                          )
                        }}
                      />
                    }
                  </div>
                </>
              }
            </>
          </>
        }
        {
          pastEvent &&
          <>
            <ProgressBar percentCompleted={100} />
            <div className='training-details'>{`You trained for ${daysDelta} days for this event.`}</div>
            <>
              {
                props.userActivitiesArr &&
                <>
                  <div>You recorded {inRange.length} training activities totalling {hoursTrained.toFixed(2)} hours for this event:</div>
                  <div className='activitieslabels'>
                    <div className='activitydetail'>Name</div>
                    <div className='activitydetail'>Date</div>
                    <div className='activitydetail'>Type</div>
                    <div className='activitydetail'>Hrs trained</div>
                    <div className='activitydetail'>Link</div>
                  </div>
                  <div className='activitiescontainer'>
                    {
                      inRange.length > 0 &&
                      <Virtuoso
                        className='activitiesvirtuoso'
                        data={inRange}
                        increaseViewportBy={200}
                        itemContent={(index, item) => {
                          return (
                            <ActivityRow className={index % 2 === 0 ? "RowEven" : "RowOdd"} key={item.index} index={item.index} item={item}/>
                          )
                        }}
                      />
                    }
                  </div>
                </>
              }
            </>
          </>
        }
        {
          futureEvent &&
          <>
            <div className='training-details'>{`You will train ${daysDelta} days for this event.`}</div>
          </>
        }
      </div>
      <button className='aimable-button' id='deleteEvent' onClick={((e) => {deleteEvent(e)})}>Delete this event</button>
      {/* allow deleting event */}
    </div>
  )
}

export default EventDetails