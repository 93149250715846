import React from 'react'
import { TbSwimming, TbBike, TbRun } from 'react-icons/tb';
import { BounceLoader } from 'react-spinners';
import ProgressBar from './ProgressBar';

function toDateTime(secs) {
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(secs);

  let month = d.getMonth()  // 10 (PS: +1 since Month is 0-based)
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  let day = d.getDate()       // 30
  let year = d.getFullYear()

  return `${monthNames[month]} ${day}, ${year}`;
}

function AimableData(props) {
  const selectEvent = (userEvent) => {
    props.selectEvent(userEvent);
  },
    getIcon = (eventType) => {
      let icons = []
      let type;
      switch (eventType) {
        case 'triathlon':
          // push swim / bike / run icons
          icons.push(<TbSwimming/>, <TbBike/>, <TbRun/>)
          type = 'Triathlon'
          break;

        case 'swim':
          // push swim icon
          icons.push(<TbSwimming/>)
          type = 'Swim'
          break;
        
        case 'run':
          // push run icon
          icons.push(<TbRun/>)
          type = 'Run'
          break;

        case 'bike':
          // push bike icon
          icons.push(<TbBike/>)
          type = 'Bike'
          break;

        default:
          break;
      }
      return {icons: icons, type: type}
    },
    obtainPercentCompleted = (userEvent) => {
      var startd = new Date(0);
      startd.setUTCSeconds(userEvent['trainingStart']['seconds']);
      var currentDT = new Date();

      let daysSinceStart = parseInt((currentDT - startd) / (1000 * 3600 * 24));

      var endd = new Date(0);
      endd.setUTCSeconds(userEvent['eventDate']['seconds']);

      let daysDelta = parseInt((endd - startd) / (1000 * 3600 * 24))

      // use start date, current date, and event date to calculate daysSinceStart & daysDelta and then return
      let percentCompleted = parseInt(daysSinceStart/daysDelta * 100)
      return percentCompleted < 100 ? percentCompleted : 100
    }
  return (
    <div id='aimable-data'>
      <div id='aimable-topbar'>
        <div id='aimable-your-events'>Your Events</div>
        <div id='aimable-create-event' className='aimable-button' onClick={((e) => {props.createAnEvent()})}>Create an Event</div>
      </div>
      {
        !props.dataObtained &&
        <div className='loading-container'>
          <BounceLoader/>
        </div>
      }
      {
        props.dataObtained &&
        <div id='aimable-events-list'>
          {
            props.userEvents.length > 0 &&
            props.userEvents.map((userEvent, index) => {
              return (
                <div key={index} className='aimable-event' onClick={((e) => {selectEvent(userEvent.eventName)})}>
                  <ProgressBar percentCompleted={obtainPercentCompleted(userEvent)} />
                  <h4>{userEvent.eventName}</h4>
                  <h5>{`Training Start Date: ${toDateTime(userEvent.trainingStart.seconds)}`}</h5>
                  <h5>{`Event Date: ${toDateTime(userEvent.eventDate.seconds)}`}</h5>
                  <div>
                    {
                      userEvent.eventType &&
                      <div title={getIcon(userEvent.eventType)['type']}>
                      {
                        getIcon(userEvent.eventType)['icons'].map((icon, icon_index) => {
                          return (<span key={`${index}${icon_index}`}>{icon}</span>)
                        })
                      }
                      </div>
                    }
                  </div>
                </div>
              )
            })
          }
          {
            props.dataObtained &&
            props.userEvents.length === 0 &&
            <>
              You haven't created an event yet.
            </>
          }
        </div>
      }
    </div>
  )
}

export default AimableData