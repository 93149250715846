import React, { useState, useEffect, useRef } from 'react';
import StravaSVG from '../../assets/StravaSVG';
import Checkbox from '../Checkbox';
import { doc, updateDoc } from "firebase/firestore";
import { fbfsdb } from '../../authentication/firebase';

function UserSettings({signUserOut, userData, user, fetch_data}) {
  const [receiveUpdates, setReceiveUpdates] = useState(userData?.receiveUpdates ? userData?.receiveUpdates : false);
  const firstRun = useRef(true);
  // run useEffect to check if user has elected to receive updates

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    let updateTheDoc = async () => {
      await updateDoc(doc(fbfsdb, `users/${user}`), {
        receiveUpdates: receiveUpdates
      })
    }
    updateTheDoc().catch(console.error)
    fetch_data();
  }, [receiveUpdates, user, fetch_data])
  
  return (
    <div id='userSettingsModal'>
      <h2>User Settings</h2>
      <div id='aimable-user-settings'>
        <div>
          <Checkbox label='Receive weekly training updates from Aimable.' setIsChecked={setReceiveUpdates} isChecked={receiveUpdates} />
        </div>
        <a href={`https://www.strava.com/oauth/authorize?client_id=98942&response_type=code&redirect_uri=https://aimable.app/exchange_token&approval_prompt=force&scope=activity:read_all`}>
          <StravaSVG/>
        </a>
        <button className='aimable-button' onClick={((e) => {signUserOut()})}>Logout</button>
      </div>
    </div>
  )
}

export default UserSettings