import React, { useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, fbfsdb } from '../authentication/firebase';
import default_photo from '../def_prof_pic.jpeg';
import aimableIcon from '../assets/Aimable Icon/Aimable Icon.001.png';
import { BounceLoader } from 'react-spinners';
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from 'firebase/firestore';

function AimableExchangeToken(props) {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    const authenticate = async () => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          "uid": user?.uid
        }
      };

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      
      let authenticateRespRaw = await fetch(`https://aimable-server.herokuapp.com/authenticateWithStrava?code=${params.code}`, requestOptions)
      let authenticateResp = await authenticateRespRaw.json();

      if (authenticateResp['error']) {
        console.log("Error authenticating...")
      }
      else {
        let access_token = authenticateResp['access_token'];
        let refresh_token = authenticateResp['refresh_token'];
        let expires_at = authenticateResp['expires_at'];
        let strava_user = authenticateResp['strava_user'];
        let klaviyoId = authenticateResp['klaviyoId'];

        // save above 3 items to firestore user profile
        await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
          stravaAccessToken: access_token,
          stravaRefreshToken: refresh_token,
          stravaExpiresAt: expires_at,
          strava_user: strava_user,
          klaviyoId: klaviyoId
        });

        // before navigating to /, let's obtain all user activities
        var myHeaders = new Headers();
        myHeaders.append("access_token", access_token);
        myHeaders.append("expires_at", expires_at);
        myHeaders.append("refresh_token", refresh_token);
        myHeaders.append("uid", user?.uid);
        myHeaders.append("strava_user", strava_user)

        var requestOptionsUserActivities = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        let userActivitiesRaw = await fetch("https://aimable-server.herokuapp.com/getUserActivities", requestOptionsUserActivities);
        let userActivities = await userActivitiesRaw.json();

        // need to set the strava accessToken, refreshToken, and expiresAt fields
        if (!Array.isArray(userActivities)) {
          // check if expiresAt val has changed
          let newAT = userActivities['access_token'];
          let newRT = userActivities['refresh_token'];
          let newEA = userActivities['expires_at'];

          if (newAT !== access_token || newRT !== refresh_token || newEA !== expires_at) {
            // set access token, refresh token, and expires at.
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
              stravaAccessToken: newAT,
              stravaRefreshToken: newRT,
              stravaExpiresAt: newEA,
              strava_user: strava_user,
              klaviyoId: klaviyoId
            });
          }
        }

        // navigate user back to /
        navigate("/");
      }
    }
    if (user) {
      authenticate().then().catch(err => console.log(err))
    }
  }, [user, loading, navigate])
  
  return (
    <div>
      <header className="App-header">
        <img src={aimableIcon} alt="Aimable icon" id='aimable-logo'/>
        {
          user &&
          <img alt='User Settings Button' src={user?.photoURL ? user?.photoURL : default_photo} id='aimable-user-img'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src=default_photo;
            }}
          />
        }
      </header>
      <div className='loading-container'>
          <BounceLoader/>
      </div>
    </div>
  )
}

export default AimableExchangeToken