import React from 'react'
import AimableData from './AimableData';
import { getDocs, collection, getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import Modal from './Modal';
import { useState, useEffect, useCallback } from 'react';
import Login from './Login/Login';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout, fbfsdb} from '../authentication/firebase';
import aimableIcon from '../assets/Aimable Icon/Aimable Icon.001.png';
import { TbSettings } from 'react-icons/tb'

function Aimable(props) {
  const [user, loading] = useAuthState(auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [whichModal, setWhichModal] = useState('');
  const [dataObtained, setDataObtained] = useState(false);
  const [userEvents, setUserEvents] = useState([]);
  const [docDataObj, setDocDataObj] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [userActivitiesArr, setUserActivitiesArr] = useState(false);
  const [userData, setUserData] = useState(false);

  const createAnEvent = () => {
    setWhichModal('Create Event');
    setModalIsOpen(true);
  },
  openUserSettings = () => {
    setWhichModal('User Settings');
    setModalIsOpen(true);
  },
  signUserOut = () => {
    setWhichModal('');
    setModalIsOpen(false);
    setModalIsOpen(false);
    setDataObtained(false);
    setUserEvents([]);
    setDocDataObj({});
    setSelectedEvent(false);
    logout();
  },
  selectEvent = (eventName) => {
    setSelectedEvent(eventName);
    setWhichModal('Event Details');
    setModalIsOpen(true);
  }

  const fetch_data = useCallback(async () => {
    setDataObtained(false)
    if (user) {
      try {
        let docsSnap = await getDocs(collection(fbfsdb, `users/${user.uid}/events`))
        let newDataObj = {}
        let newData = docsSnap.docs.map((doc) => {
          let docData = {...doc.data()}
          let docName = doc.id
          let docObj = {
            ...docData,
            eventName: docName
          }
          newDataObj[docName] = docData
          return docObj
        });
  
        if (newData) {
          setDocDataObj(newDataObj)
          setUserEvents(newData);
          setDataObtained(true);
        }

        let userFieldsSnapshot = await getDoc(doc(fbfsdb, `users/${user.uid}`));
        let userFields = userFieldsSnapshot.data();
        if (!userFields) {
          // create the doc
          await setDoc(doc(fbfsdb, `users/${user.uid}`), {})
        }
        setUserData(userFields);
        let klaviyoId;
        let obtainedKlaviyoId = false;
        if (userFields?.klaviyoId) {
          klaviyoId = userFields?.klaviyoId;
        }
        else {
          // add user to klaviyo audience and obtain klaviyo id for that user
          let klaviyoUrl = `https://aimable-server.herokuapp.com/createKlaviyoUser`
          var ckurequestOptions = {
            method: 'GET',
            headers: {
              "uid": user?.uid
            }
          }

          let ckuRaw = await fetch(klaviyoUrl, ckurequestOptions);
          let cku = await ckuRaw.json();
          klaviyoId = cku['id'];
          obtainedKlaviyoId = true;
        }
        if (userFields?.stravaAccessToken) {
          let access_token = userFields?.stravaAccessToken;
          let refresh_token = userFields?.stravaRefreshToken;
          let expires_at = userFields?.stravaExpiresAt;
          let strava_user = userFields?.strava_user;

          // if stravaEnabled, get training data either from aimable server.
          var myHeaders = new Headers();
          myHeaders.append("access_token", access_token);
          myHeaders.append("expires_at", expires_at);
          myHeaders.append("refresh_token", refresh_token);
          myHeaders.append("uid", user?.uid);
          myHeaders.append("strava_user", strava_user);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          let userActivitiesRaw = await fetch("https://aimable-server.herokuapp.com/getUserActivities", requestOptions);
          let userActivities = await userActivitiesRaw.json();

          if (Array.isArray(userActivities)) {
            setUserActivitiesArr(userActivities);
            if (obtainedKlaviyoId) {
              await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                stravaAccessToken: access_token,
                stravaRefreshToken: refresh_token,
                stravaExpiresAt: expires_at,
                strava_user: strava_user,
                klaviyoId: klaviyoId
              });
            }
          }
          else {
            // check if expiresAt val has changed
            let newAT = userActivities['access_token'];
            let newRT = userActivities['refresh_token'];
            let newEA = userActivities['expires_at'];
            let newStravaUser = userActivities['strava_user'];

            if (newAT !== access_token || newRT !== refresh_token || newEA !== expires_at || newStravaUser !== strava_user || obtainedKlaviyoId) {
              // set access token, refresh token, and expires at.
              await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                stravaAccessToken: newAT,
                stravaRefreshToken: newRT,
                stravaExpiresAt: newEA,
                strava_user: newStravaUser,
                klaviyoId: klaviyoId
              });
            }
            setUserActivitiesArr(userActivities['data']);
          }
        }
        else {
          await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
            klaviyoId: klaviyoId
          });
        }
      } catch(error) {
        console.log(error)
      }
    }
  }, [user])

  useEffect(() => {
    if (loading) {
      // show loading screen
      console.log('loading')
    }
    else {
      fetch_data();
    }
  }, [loading, fetch_data])


  return (
    <>
      <header className="App-header">
        <img src={aimableIcon} alt="Aimable icon" id='aimable-logo'/>
        {
          user &&
          <TbSettings id='aimable-user-img' onClick={((e) => {openUserSettings()})} />
        }
      </header>
      {
        user &&
        <>
          <AimableData createAnEvent={createAnEvent} userEvents={userEvents} dataObtained={dataObtained} selectEvent={selectEvent} />
          {
            modalIsOpen &&
            <Modal whichModal={whichModal} setModalIsOpen={setModalIsOpen} signUserOut={signUserOut} fetch_data={fetch_data} user={user.uid} docDataObj={docDataObj} selectedEvent={selectedEvent} userActivitiesArr={userActivitiesArr} userData={userData} userName={user.displayName}/>
          }
        </>
      }
      {
        !user &&
        !loading &&
        <div id='aimable-login-container'>
          <Login />
        </div>
      }
    </>
  )
}

export default Aimable