import React, { useState, useRef, useEffect } from 'react';
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { fbfsdb } from '../../authentication/firebase';
import Select from 'react-select';
import Checkbox from '../Checkbox';
import DatePicker from 'react-date-picker'
import aimableIcon from '../../assets/Aimable Icon/Aimable Icon.001.png';

function CreateEvent({fetch_data, user, setModalIsOpen, userData, userName}) {
  const [receiveUpdates, setReceiveUpdates] = useState(userData?.receiveUpdates ? userData?.receiveUpdates : false);
  const firstRun = useRef(true);
  const [eventName, setEventName] = useState(userName + "'s event");
  const [trainingStart, setTrainingStart] = useState(null);
  const [dateEvent, setDateEvent] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [startDateRequired, setStartDateRequired] = useState('');
  const [dateEventRequired, setDateEventRequired] = useState('');
  const [eventTypeRequired, setEventTypeRequired] = useState('');
  const [nameRequired, setNameRequired] = useState('');
  const [startDateFocused, setStartDateFocused] = useState('');
  const [eventDateFocused, setEventDateFocused] = useState('');
  const [eventTypeFocused, setEventTypeFocused] = useState('');
  const [submitAllowed, setSubmitAllowed] = useState(false);

  const focusStartDate = () => {
    setStartDateRequired('');
    setStartDateFocused('focused')
  },
    unfocusStartDate = () => {
      setStartDateFocused('');
    },
    focusEventDate = () => {
      setDateEventRequired('');
      setEventDateFocused('focused')
    },
    unfocusEventDate = () => {
      setEventDateFocused('');
    },
    focusEventType = () => {
      setEventTypeRequired('');
      setEventTypeFocused('focused');
    },
    unfocusEventType = () => {
      setEventTypeFocused('')
    }

  const createANewEvent = async(e) => {
    e.preventDefault();
    let error = false;
    if (eventName.trim().length === 0) {
      setNameRequired('required');
      error = true;
    }

    if (trainingStart === null) {
      setStartDateRequired('required');
      error = true;
    }
    
    if (dateEvent === null) {
      setDateEventRequired('required')
      error = true;
    }
    
    if (eventType === null) {
      setEventTypeRequired('required');
      error = true;
    }

    if (error) {
      return
    }
    else {
      setStartDateRequired('');
      setDateEventRequired('');
      setEventTypeRequired('');
      setNameRequired('');
    }

    // create a new users/{uid}/events/doc
    await setDoc(doc(fbfsdb, `users/${user}/events`, eventName), {
      eventDate: dateEvent,
      trainingStart: trainingStart,
      eventType: eventType['value']
    });
    
    fetch_data();
    setModalIsOpen(false)
  },
    updateEvent = (e) => {
      setEventName(e.target.value);
    },
    updateEventType = (e) => {
      setEventType(e);
    },
    handleFocus = (event) => event.target.select();

  const eventOptions = [
    { value: 'triathlon', label: 'Triathlon' },
    { value: 'run', label: 'Run' },
    { value: 'bike', label: 'Bike'},
    { value: 'swim', label: 'Swim' }
  ]
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    let updateTheDoc = async () => {
      await updateDoc(doc(fbfsdb, `users/${user}`), {
        receiveUpdates: receiveUpdates
      })
    }
    updateTheDoc().catch(console.error)
    fetch_data();
  }, [receiveUpdates, user, fetch_data])

  useEffect(() => {
    // check if eventName, eventType, startDate, and eventDate are set.
    if (eventName && eventName.length > 0 && eventType && dateEvent && trainingStart) {
      setSubmitAllowed(true);
    }
  }, [eventName, eventType, dateEvent, trainingStart])

  return (
    <div>
      <img src={aimableIcon} alt="Aimable icon" className='aimable-logo-modal'/>
      <h2 id='newEventTitle'>Create a new event</h2>
      <form id='newEventForm' onSubmit={((e) => {createANewEvent(e)})}>
        <label className='newEventDetail'>
          <div className='newEventDetailLabel'>Name</div>
          <input autoComplete="off" id='newEventName' autoFocus onChange={((e) => {updateEvent(e)})} value={eventName} className={nameRequired}  onFocus={handleFocus}/>
        </label>
        <label className='newEventDetail'>
          <div className='newEventDetailLabel'>Event type</div>
          <Select isClearable options={eventOptions} className={'eventTypeSelector ' + eventTypeFocused + ' ' + eventTypeRequired} placeholder='Event Type' styles={customStyles} onChange={((e) => updateEventType(e))} value={eventType} onFocus={((e)=> {focusEventType(e)})} onBlur={((e)=> {unfocusEventType(e)})}/>
        </label>
        {/* instead of using date range picker, use two date fields for training start date + event date */}
        <div className='newEventDetail split'>
          <label>
            <div className='newEventDetailLabel'>Training start date</div>
            <DatePicker calendarType="US" onChange={setTrainingStart} maxDate={dateEvent ? dateEvent : null} value={trainingStart} calendarIcon={null} className={'raceRange ' + startDateRequired + ' ' + startDateFocused} onFocus={((e) => {focusStartDate(e)})}  onCalendarClose={((e) => {unfocusStartDate(e)})} />
          </label>
          <div className='splitseparator'></div>
          <label>
            <div className='newEventDetailLabel'>Event date</div>
            <DatePicker calendarType="US" onChange={setDateEvent} minDate={trainingStart ? trainingStart : null} value={dateEvent} calendarIcon={null}  className={'raceRange ' + dateEventRequired + ' ' + eventDateFocused} onFocus={((e) => {focusEventDate(e)})} onCalendarClose={((e) => {unfocusEventDate(e)})} />
          </label>
        </div>
        <div className='newEventDetail'>
          <Checkbox label='Receive weekly training updates from Aimable.' setIsChecked={setReceiveUpdates} isChecked={receiveUpdates} />
        </div>
        <button id='createEventBtn' className={'aimable-button ' + (submitAllowed ? 'submitAllowed' : 'submitNotAllowed')} type='submit'>Create event</button>
      </form>
    </div>
  )
}

export default CreateEvent