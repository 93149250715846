import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";
import StyledFirebaseAuth from "./StyledFirebaseAuth.tsx";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCTxeAoUsyQXfcYaDE0hRK1giQq85NZSMw",
  authDomain: "aimable-32d6c.firebaseapp.com",
  projectId: "aimable-32d6c",
  storageBucket: "aimable-32d6c.appspot.com",
  messagingSenderId: "29262852576",
  appId: "1:29262852576:web:0db650a8c6c87f61a03b85",
  measurementId: "G-XD6YKQL2W9"
};

const app = firebase.initializeApp(firebaseConfig);
export const fbfsdb = getFirestore(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const firebaseUiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      return false;
    },
    uiShown: () => {
    },
  },
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
};

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};


const LoginOptions = () => {
  return (
    <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
  )
}

export {
  auth,
  signInWithGoogle,
  LoginOptions,
  logout,
};
