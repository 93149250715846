const Checkbox = ({ label, setIsChecked, isChecked, ...props }) => {
  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          defaultChecked={isChecked}
          className={isChecked ? "checked" : ""}
          onChange={() => setIsChecked((prev) => !prev)}
          {...props}/>
        <span>{label}</span>
      </label>
    </div>
  );
};
export default Checkbox;