import React from 'react'

function ActivityRow(props) {
  function toDateTime(secs) {
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(secs);
  
    let month = d.getMonth()  // 10 (PS: +1 since Month is 0-based)
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    let day = d.getDate()       // 30
    let year = d.getFullYear()
  
    return `${monthNames[month]} ${day}, ${year}`;
  }
  return (
    <div className={props.className + " activityitem"}>
      {/* each activity should contain name, date, type, hours logged, button to open in strava. */}
      {
        props.item &&
        <>
          <div className='activitydetail'>{props.item.name}</div>
          <div className='activitydetail'>{toDateTime(new Date(props.item.start_date).getTime() / 1000)}</div>
          <div className='activitydetail'>{props.item.type}</div>
          <div className='activitydetail'>{(props.item.elapsed_time / 60 / 60).toFixed(2)}</div>
          <a className='activitydetail' rel="noreferrer" href={`https://www.strava.com/activities/${props.item.id}`} target='_blank'><div>View on Strava</div></a>
        </>
      }
    </div>
  )
}

export default ActivityRow