import React from 'react'

function ProgressBar({percentCompleted}) {
  return (
    <div className='percentageBarOuter'>
      <div className="percentageBar" style={{width: percentCompleted + "%"}}>&nbsp;</div>
    </div>
  )
}

export default ProgressBar