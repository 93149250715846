import React from 'react';
import { MdOutlineClose } from 'react-icons/md'
import CreateEvent from './Modals/CreateEvent';
import EventDetails from './Modals/EventDetails';
import UserSettings from './Modals/UserSettings';

function Modal(props) {
  return (
    <div id='aimable-modal' onClick={(() => {props.setModalIsOpen(false)})}>
      <div id='aimable-modal-content' onClick={((e) => {e.stopPropagation()})} >
        <MdOutlineClose id='aimable-close-modal' onClick={(() => {props.setModalIsOpen(false)})} />
        {
          props.whichModal === 'Create Event' &&
          <CreateEvent fetch_data={props.fetch_data} user={props.user} setModalIsOpen={props.setModalIsOpen} userData={props.userData} userName={props.userName} />
        }
        {
          props.whichModal === 'User Settings' &&
          <UserSettings signUserOut={props.signUserOut} userData={props.userData} user={props.user} fetch_data={props.fetch_data} />
        }
        {
          props.whichModal === "Event Details" &&
          <EventDetails eventData={props.docDataObj} selectedEvent={props.selectedEvent} user={props.user} fetch_data={props.fetch_data} setModalIsOpen={props.setModalIsOpen} userActivitiesArr={props.userActivitiesArr}/>
        }
      </div>
    </div>
  )
}

export default Modal