import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Aimable from './components/Aimable';
import AimableExchangeToken from './components/AimableExchangeToken';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Aimable />} />
          <Route path='/exchange_token' element={<AimableExchangeToken />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
