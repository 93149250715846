import React from 'react';
import { LoginOptions } from '../../authentication/firebase';

function Login() {
  return (
    <div id='aimable-login'>
      <h2>Aimable helps you understand your training patterns and accomplish your fitness goals</h2>   
      <p>Aimable tracks your progress for races and other events. By seamlessly integrating with Strava,
        you'll always have accurate data on how much you've trained for an event and how much you still need to train.</p>
      <h4>To get started, sign in using Google or your own email address and password.</h4>
      <h5>NOTE: By signing up, you agree to receive weekly emails from Aimable about your progress toward events.</h5>
      <LoginOptions />
    </div>
  )
}

export default Login